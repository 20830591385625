<template>
  <Transition name="pan">
    <div v-if="shouldDisplay" class="toast-container" :style="colorStyle">
      <div class="toast-layout">
        <div>
          <font-awesome-icon
            class="toast-icon"
            :icon="['fal', icon]"
            :style="colorStyle"
          />
        </div>
        <div class="toast-content">
          <div class="toast-title">{{ title }}</div>
          <div class="toast-body">{{ body }}</div>
          <el-button
            :type="type"
            size="mini"
            class="toast-cta"
            @click="$emit('cta-click')"
          >
            {{ ctaText }}
          </el-button>
        </div>
      </div>
      <font-awesome-icon
        class="toast-close"
        :icon="['fal', 'times']"
        :style="colorStyle"
        @click="$emit('close')"
      />
    </div>
  </Transition>
</template>

<script>
import {
  green5Mayday,
  yellow5Mayday,
  green1Mayday,
  yellow1Mayday,
} from '@/assets/scss/_colors.scss';

export default {
  props: {
    shouldDisplay: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'success',
    },
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    ctaText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      typeToColor: {
        success: { main: green5Mayday, hover: green1Mayday },
        warning: { main: yellow5Mayday, hover: yellow1Mayday },
        default: { main: green5Mayday, hover: green1Mayday },
      },
    };
  },
  computed: {
    typeColor() {
      return this.typeToColor[this.type] || this.typeToColor.default;
    },
    colorStyle() {
      return {
        '--hover-color': this.typeColor.hover,
        '--main-color': this.typeColor.main,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-container {
  z-index: 3000;
  top: 14px;
  right: 20px;
  position: fixed;
  width: 420px;
  background-color: white;
  border-radius: 4px;
  border-left: 8px solid var(--main-color);
  box-shadow: $shadow-mayday;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  animation: toast-open 0.4s ease-in-out;
}

.toast-layout {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 24px 18px 18px 8px;
}

.toast-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  line-height: 14px;
}

.toast-icon {
  margin-bottom: 4px;
  color: var(--main-color);
}

.toast-title {
  font-weight: 700;
  font-size: 16px;
}

.toast-body {
  font-size: 12px;
  color: $grey-6-mayday;
}

.toast-close {
  position: absolute;
  top: 6px;
  right: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 2px;
  color: var(--main-color);

  &:hover {
    background-color: var(--hover-color);
  }
}

.toast-cta {
  align-self: flex-end;
}

.pan-leave-active {
  animation: toast-close 0.4s ease-in-out;
}

@keyframes toast-close {
  0% {
    height: auto;
    transform: translateX(0);
  }
  100% {
    height: 0;
    margin-top: 0;
    transform: translateX(450px);
  }
}

@keyframes toast-open {
  0% {
    max-height: 0;
    transform: translateX(450px);
  }
  100% {
    max-height: 130px;
    transform: translateX(0);
  }
}
</style>
