<template>
  <div
    class="d-flex suggestion-item align-items-center justify-between"
    :class="[
      {
        'background-grey': hovered,
        'is-hovered': hovered,
      },
    ]"
    role="button"
    @click="$emit('go-to-document', item)"
  >
    <div class="d-flex align-items-center label-wrapper">
      <article-type-icon
        class="d-flex align-items-center"
        :item="item"
      ></article-type-icon>
      <TextEllipsis :text="translatedLabel" fontSize="14px"></TextEllipsis>
    </div>
    <div class="d-flex flex-row align-items-center gap-2">
      <font-awesome-icon
        :icon="[checked == true ? 'fas' : 'fal', 'check-circle']"
        class="icon green"
        @click.stop="handleClickOnCheck(true)"
      />
      <font-awesome-icon
        :icon="[checked == false ? 'fas' : 'fal', 'times-circle']"
        class="icon red"
        @click.stop="handleClickOnCheck(false)"
      />
    </div>
  </div>
</template>

<script>
import ArticleTypeIcon from 'components/SuperSearch/ArticleTypeIcon.vue';
import TextEllipsis from 'components/TextEllipsis.vue';

export default {
  name: 'content-checker-item',
  components: {
    ArticleTypeIcon,
    TextEllipsis,
  },
  data() {
    return {
      checked: null,
    };
  },
  props: {
    item: Object,
    hovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    translatedLabel() {
      return this.item.translations[this.item.defaultLanguage].label;
    },
  },
  methods: {
    handleClickOnCheck(state) {
      this.checked = state;
      this.$emit('set-content-check', { [this.item.id]: state });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  height: 22px;
}
.green {
  color: $green-mayday;
}
.red {
  color: $red-mayday;
}
.suggestion-item {
  padding: 6px 16px;
  cursor: pointer;
  min-height: 40px;
}
.background-grey {
  background: $grey-1-mayday;
}
.marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
}
.bg-yellow {
  background: $yellow-mayday;
}
.bg-blue {
  background: $blue-mayday;
}
.bg-grey {
  background: $grey-5-mayday;
}
.bg-black {
  background: $grey-8-mayday;
}
.btn-enter {
  font-size: 8px;
  font-weight: bold;
  color: $grey-6-mayday;
  background: $grey-1-mayday;
  border: 0.5px solid $grey-4-mayday;
  border-radius: 2px;
  cursor: pointer;
}
.hidden {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
.overflow {
  overflow: hidden;
  white-space: nowrap;
}
.label {
  font-size: 14px;
  font-weight: 500;
  color: $grey-9-mayday;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
}
.label-row {
  display: flex;
  align-items: center;
}

.full-labels {
  margin-top: 4px;
}

.explain-prompt {
  display: inline-flex;
  line-height: 0.1px;
}

.time-ago {
  white-space: nowrap;
}

.is-hidden-sticker {
  background-color: red;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  left: 29px;
  top: 9px;
}
.label-wrapper {
  max-width: calc(100% - 50px);
}
</style>
