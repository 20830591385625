<template>
  <headless-modal :open="open" @close="handleClose">
    <div
      class="base-modal-container"
      :class="[sizeClass]"
      :style="{ width: customWidth, height: customHeight }"
    >
      <!-- Sidebar -->
      <div v-if="sidebar" class="base-modal-sidebar">
        <div v-if="subTitle" class="base-modal-subtitle">
          {{ subTitle }}
        </div>
        <slot name="sidebar">
          <div class="base-modal-sidebar-placeholder"></div>
        </slot>
      </div>

      <!-- Content -->
      <div class="base-modal-content">
        <!-- Header -->
        <div v-if="title || description" class="base-modal-header">
          <div v-if="title" class="base-modal-title-container">
            <span class="base-modal-title">{{ title }}</span>
            <base-button
              icon="close"
              variant="tertiary"
              icon-only
              @click="$emit('close')"
            />
          </div>
          <p v-if="description" class="base-modal-description">
            {{ description }}
          </p>
        </div>

        <!-- Body -->
        <slot>
          <div :class="slotClass" class="base-modal-body-placeholder"></div>
        </slot>

        <!-- Footer -->
        <slot name="footer">
          <div class="base-modal-footer" v-if="cancelText || confirmText">
            <base-button
              v-if="cancelText"
              variant="secondary"
              :size="buttonSize"
              :text="cancelText"
              :disabled="loading"
              @click="$emit('cancel')"
            />
            <base-button
              v-if="confirmText"
              :size="buttonSize"
              :variant="variant"
              :text="confirmText"
              :loading="loading"
              :disabled="disabled"
              @click="$emit('confirm')"
            />
          </div>
        </slot>
      </div>
    </div>
  </headless-modal>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    title: String,
    subTitle: String,
    description: String,
    size: {
      type: String,
      default: 'sm',
      validator: (value) => ['sm', 'md', 'lg'].includes(value),
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'danger'].includes(value),
    },
    confirmText: {
      type: String,
    },
    cancelText: String,
    sidebar: Boolean,
    customWidth: String,
    customHeight: String,
    loading: Boolean,
    disabled: Boolean,
    buttonSize: {
      type: String,
      default: 'md',
    },
  },
  methods: {
    handleClose() {
      if (this.loading) return;
      this.$emit('close');
    },
  },
  computed: {
    sizeClass() {
      const SIZE_STYLES = {
        sm: 'base-modal-w-sm',
        md: 'base-modal-w-md',
        lg: 'base-modal-w-lg',
      };
      return SIZE_STYLES[this.size];
    },
    // used for dev purposes
    slotClass() {
      const SLOT_STYLES = {
        sm: 'base-modal-h-sm',
        md: 'base-modal-h-md',
        lg: 'base-modal-h-lg',
      };
      return SLOT_STYLES[this.size];
    },
  },
};
</script>

<style lang="scss">
/* Base Modal Styles */
.base-modal-container {
  display: flex;
  border-radius: 8px;
  background: white;
  box-shadow: $shadow-lg-mayday;
}

.base-modal-sidebar {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $grey-3-mayday;
}

.base-modal-sidebar-placeholder {
  flex: 1;
  background-color: rgba(0, 122, 255, 0.1);
  border-radius: 4px;
  width: 172px;
}

.base-modal-subtitle {
  font-size: 1rem;
  color: $grey-9-mayday;
}

.base-modal-content {
  padding: 16px;
  padding-bottom: 12px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.base-modal-content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12px * var(--tw-space-y-reverse));
}

.base-modal-content-header-padding {
  padding-top: 16px;
}

.base-modal-header > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

.base-modal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.base-modal-title {
  font-size: 20px;
  line-height: 24px;
  color: $grey-9-mayday;
}

.base-modal-description {
  font-size: 12px;
  line-height: 16px;
  color: $grey-7-mayday;
}

.base-modal-body-placeholder {
  background-color: rgba(0, 122, 255, 0.1);
  border-radius: 4px;
}

.base-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

/* Size Classes */
.base-modal-w-sm {
  width: 400px;
}

.base-modal-w-md {
  width: 600px;
}

.base-modal-w-lg {
  width: 1000px;
}

.base-modal-h-sm {
  height: 264px;
}

.base-modal-h-md {
  height: 364px;
}

.base-modal-h-lg {
  height: 564px;
}
</style>
