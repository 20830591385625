<template>
  <div class="relative">
    <div class="bg-white rounded-circle header-icon">
      <div class="p-3 rounded-circle d-inline-flex" :style="styleName">
        <slot name="icon"></slot>
      </div>
    </div>
    <div class="text-center header-container" :style="styleName">
      <slot></slot>
      <div class="header-footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
      <div v-else class="header-without-footer"></div>
    </div>
  </div>
</template>

<script>
import { grey1Mayday } from '@/assets/scss/_colors.scss';

export default {
  name: 'modal-header',
  props: {
    styleName: {
      type: Object,
      default: () => {
        return {
          backgroundColor: grey1Mayday,
        };
      },
    },
  },
};
</script>

<style>
.header-icon {
  position: absolute;
  top: 0%;
  left: 50%;
  padding: 6px;
  transform: translate(-50%, -50%);
}
.header-container {
  margin-top: 24px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 2px;
}
.header-footer {
  margin-top: 12px;
  padding-top: 14px;
  padding-bottom: 9px;
  border-top: 1px solid white;
}
.header-without-footer {
  padding-bottom: 24px;
}
</style>
