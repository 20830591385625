export class ExportService {
  constructor(data) {
    this.data = data;
  }

  async putKBExport({ knowledgeId, type }) {
    if (type === 'overview') {
      const response = await this.data.putKBOverviewExport({ knowledgeId });
      return response.data;
    }
    if (type === 'contents') {
      const response = await this.data.putKBContentsExport({ knowledgeId });
      return response.data;
    }
  }
  async getKBExport({ knowledgeId, type }) {
    if (type === 'overview') {
      const response = await this.data.getKBOverviewExport({ knowledgeId });
      return response.data;
    }
    if (type === 'contents') {
      const response = await this.data.getKBContentsExport({ knowledgeId });
      return response.data;
    }
  }

  async getExportsPredefinedFilters(exportType) {
    return this.data.getExportsPredefinedFilters(exportType);
  }

  async getExportGlobalAttempts(predefinedFilterId) {
    return this.data.getExportGlobalAttempts(predefinedFilterId);
  }

  async getArticlePDFExport(lang, contentId) {
    return this.data.getArticlePDFExport(lang, contentId);
  }
}
