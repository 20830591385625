import * as types from "./mutation-types";

export default {
    [types.APPEND_PDF_EXPORT](state, { contentId, lang, contentLabel }) {
        const existingExportIndex = state.pdfExports.findIndex(
            (exportItem) => exportItem.contentId === contentId && exportItem.lang === lang,
        );
        if (existingExportIndex > -1 && state.pdfExports[existingExportIndex].type === "info") {
            throw new Error("PDF export already exists");
        }
        state.pdfExports = [
            ...state.pdfExports,
            {
                contentId,
                lang,
                contentLabel,
                type: "info",
                display: true,
            }
        ]
    },

    [types.UPDATE_PDF_EXPORT](state, payload) {
        const existingExportIndex = state.pdfExports.findIndex(
            (exportItem) => exportItem.contentId === payload.contentId && exportItem.lang === payload.lang,
        );
        if (existingExportIndex === -1) {
            throw new Error("PDF export does not exist");
        }
        const newExports = state.pdfExports
        newExports[existingExportIndex] = {
            ...state.pdfExports[existingExportIndex],
            ...payload,
        };
        state.pdfExports = [...newExports];
    },

    [types.DELETE_PDF_EXPORT](state, {contentId, lang}) {
        const existingExportIndex = state.pdfExports.findIndex(
            (exportItem) => exportItem.contentId === contentId && exportItem.lang === lang,
        );
        if (existingExportIndex === -1) return
        if(state.pdfExports[existingExportIndex].type === "info") {
            state.pdfExports[existingExportIndex].display = false;
            return;
        }
        const newExports = [...state.pdfExports]
        newExports.splice(existingExportIndex, 1);
        state.pdfExports = [...newExports];
    }
}