import data from './task.data.raw';

export class TaskServiceData {
  constructor(client) {
    this.client = client;
  }

  getBacklogTasksCount() {
    return this.client
      .query({ query: data.getBacklogTasksCount, fetchPolicy: 'no-cache' })
      .then((response) => response.data.backlogTasksCount)
      .catch((error) => error);
  }

  // Task views
  getSettingsTaskViews(page = 1) {
    return this.client
      .query({
        query: data.getSettingsTaskViews,
        variables: { page },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getSettingsTaskViews)
      .catch((error) => console.log(error));
  }

  getTaskView(id) {
    return this.client
      .query({
        query: data.getTaskView,
        variables: { id },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.taskView)
      .catch((error) => console.log(error));
  }

    getTaskViews() {
        return this.client
            .query({
                query: data.getTaskViews,
                fetchPolicy: 'no-cache',
                variables: {},
            })
            .then((response) => response.data.getTaskViews)
            .catch((error) => console.log(error));
    }

  createTaskView({ name, filters, description }) {
    return this.client
      .mutate({
        mutation: data.createTaskView,
        variables: { input: { name, filters, description } },
      })
      .then((response) => response.data.createTaskView)
      .catch((error) => console.log(error));
  }

  updateTaskView({ name, filters, description, id }) {
    return this.client
      .mutate({
        mutation: data.updateTaskView,
        variables: { input: { name, filters, description, id } },
      })
      .then((response) => response.data.updateTaskView)
      .catch((error) => console.log(error));
  }

  deleteTaskView(id) {
    return this.client
      .mutate({
        mutation: data.deleteTaskView,
        variables: { id },
      })
      .then((response) => response.data.deleteTaskView)
      .catch((error) => console.log(error));
  }
}
