import * as types from './mutation-types';

const entityToAction = {
  NOTIFICATION: 'processNotification',
  TASK: 'processTask',
  CONTENT: 'processContent',
  CONTENT_THREAD: 'processContentThread',
  USERS: 'processUsers',
};

const reconnectStates = [WebSocket.CLOSED, WebSocket.CLOSING];

let ackId = 0;
let shouldForceReconnect = false;
let websocketClient;

export default {
  setWebsocketData({ commit, dispatch }, payload) {
    commit(types.SET_WEBSOCKET_DATA, payload);
    dispatch('createWebsocket');
  },

  logout({ commit, dispatch }) {
    commit(types.LOGOUT);
    dispatch('closeWebsocket');
  },

  closeWebsocket() {
    try {
      if (websocketClient) {
        return websocketClient.close();
      }
    } catch (error) {
      return null;
    }
  },

  async createWebsocket({ dispatch, getters, commit }) {
    try {
      const { connectionUrl, connectionGroups } = getters;
      if (!connectionUrl) return;

      await dispatch('closeWebsocket');

      websocketClient = new WebSocket(connectionUrl, 'json.webpubsub.azure.v1');

      websocketClient.onmessage = (event) => {
        const jsonData = JSON.parse(event.data);
        if (
          jsonData.type &&
          jsonData.type === 'system' &&
          jsonData.connectionId
        ) {
          dispatch('adminModule/setWSConnectionId', jsonData.connectionId, {
            root: true,
          });
        } else dispatch(`processWebsocketEvent`, jsonData.data);
      };

      websocketClient.onopen = async () => {
        if (websocketClient.readyState !== WebSocket.OPEN) {
          await new Promise((r) => setTimeout(r, 2000));
        }
        try {
          connectionGroups.forEach((group) => {
            websocketClient.send(
              JSON.stringify({
                type: 'joinGroup',
                group,
                ackId: ackId++,
              }),
            );
          });
        } catch (error) {
          console.error(error);
          console.error('Error while trying to join group');
          shouldForceReconnect = true;
        }

        commit(types.SET_IS_CONNECTED, true);
      };

      websocketClient.onclose = () => {
        commit(types.SET_IS_CONNECTED, false);
      };
    } catch (error) {
      websocketClient = null;
      console.error(error);
      console.error('Error while trying to create websocket connection');
    } finally {
      dispatch('pollWebsocketState');
    }
  },

  pollWebsocketState({ dispatch, commit }) {
    const timeoutId = setTimeout(() => {
      let websocketState;

      try {
        websocketState = websocketClient.readyState;
      } catch (error) {
        websocketState = WebSocket.CLOSED;
      }

      if (
        !websocketClient ||
        reconnectStates.includes(websocketState) ||
        shouldForceReconnect
      ) {
        shouldForceReconnect = false;
        dispatch('createWebsocket');
      } else {
        dispatch('pollWebsocketState');
      }
    }, 5000);

    commit(types.SET_TIMEOUT_ID, timeoutId);
  },

  async processWebsocketEvent({ dispatch }, event) {
    if (!event) return;

    const { entityType } = event;
    const action = entityToAction[entityType];
    if (!action) return;
    return dispatch(action, event);
  },

  processNotification(_, event) {
    const { eventType, id } = event;

    switch (eventType) {
      case 'CREATE':
      case 'UPDATE':
        window.dispatchEvent(
          new CustomEvent('md-getNotificationById', {
            detail: {
              id,
              isFromNotificationCenter:
                this.$router.currentRoute.name === 'notification-center',
            },
          }),
        );
        break;
      case 'DELETE':
        window.dispatchEvent(
          new CustomEvent('md-deleteNotificationFromStore', {
            detail: id,
          }),
        );
        break;
      default:
        break;
    }
  },

  processTask({ dispatch }, event) {
    const { eventType } = event;
    switch (eventType) {
      case 'CREATE':
      case 'UPDATE':
        dispatch('taskModule/getBacklogTasksCount', null, { root: true });
        break;
      default:
        break;
    }
  },

  processContent({ commit, rootGetters }, event) {
    const { eventType, id } = event;
    const focusContent = rootGetters['knowledgeModule/focusContent'];

    switch (eventType) {
      case 'CREATE':
      case 'UPDATE':
        if (focusContent && focusContent.id === id) {
          commit(
            'knowledgeModule/SET_FOCUS_CONTENT',
            { ...focusContent, updatedAt: Date.now().toString() },
            { root: true },
          );
        }
        break;
      default:
        break;
    }
  },

  processContentThread({ dispatch, rootGetters }, event) {
    const {
      eventType,
      metadata: { entityId, entityType, lang },
      id: threadId,
    } = event;
    const focusContent = rootGetters['knowledgeModule/focusContent'];

    switch (eventType) {
      case 'CREATE':
      case 'UPDATE':
        if (focusContent && focusContent.id === entityId) {
          dispatch(
            'threadModule/getContentThreadById',
            { entityId, entityType, threadId, lang },
            { root: true },
          );
        }
        break;
      default:
        break;
    }
  },

  processUsers({ dispatch, rootGetters }, event) {
    if (event.userId === rootGetters.userId) return;
    if (event.status === 'CONNECTED') dispatch('manifestContentGroup', event);
    dispatch('adminModule/updateWPSConnectedUsers', event, { root: true });
  },

  async joinContentGroup({ getters, rootGetters }, { contentId, lang }) {
    const { connectionGroups } = getters;
    if (!connectionGroups || !contentId || !lang) return;
    try {
      await websocketClient.send(
        JSON.stringify({
          type: 'joinGroup',
          group: `EDIT_${contentId}_${lang}`,
          ackId: ackId++,
        }),
      );
      await websocketClient.send(
        JSON.stringify({
          type: 'sendToGroup',
          group: `EDIT_${contentId}_${lang}`,
          data: {
            entityType: 'USERS',
            status: 'CONNECTED',
            userId: rootGetters.userId,
            content: `${contentId}_${lang}`,
          },
        }),
      );
    } catch (e) {
      console.log(e);
    }
  },

  async leaveContentGroup(
    { rootGetters, getters, dispatch },
    { contentId, lang },
  ) {
    const { connectionGroups } = getters;
    if (!connectionGroups || !contentId || !lang) return;
    try {
      dispatch('adminModule/clearWPSConnectedUsers', `${contentId}_${lang}`, {
        root: true,
      });
      websocketClient.send(
        JSON.stringify({
          type: 'sendToGroup',
          group: `EDIT_${contentId}_${lang}`,
          data: {
            entityType: 'USERS',
            status: 'DISCONNECTED',
            userId: rootGetters.userId,
            content: `${contentId}_${lang}`,
          },
        }),
      );
      websocketClient.send(
        JSON.stringify({
          type: 'leaveGroup',
          group: `EDIT_${contentId}_${lang}`,
          content: `${contentId}_${lang}`,
          ackId: ackId++,
        }),
      );
    } catch (e) {
      console.log(e);
    }
  },

  async idleContentGroup(
    { rootGetters, getters },
    { contentId, lang, isIdle },
  ) {
    const { connectionGroups } = getters;
    if (!connectionGroups || !contentId || !lang) return;
    try {
      await websocketClient.send(
        JSON.stringify({
          type: 'sendToGroup',
          group: `EDIT_${contentId}_${lang}`,
          data: {
            entityType: 'USERS',
            status: 'IS_IDLE',
            userId: rootGetters.userId,
            content: `${contentId}_${lang}`,
            value: isIdle,
          },
        }),
      );
    } catch (e) {
      console.log(e);
    }
  },

  async manifestContentGroup({ rootGetters, getters }, { content }) {
    const { connectionGroups } = getters;
    if (!connectionGroups || !content) return;
    try {
      await websocketClient.send(
        JSON.stringify({
          type: 'sendToGroup',
          group: `EDIT_${content}`,
          data: {
            entityType: 'USERS',
            status: 'ACK',
            userId: rootGetters.userId,
            content,
          },
        }),
      );
    } catch (e) {
      console.log(e);
    }
  },
};
