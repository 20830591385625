<template>
  <div :class="['atg-toast', { expanded: status === 'DONE' }]">
    <div class="atg-toast__close" @click="handleClose">
      <font-awesome-icon :icon="['fal', 'times']" />
    </div>
    <div class="atg-toast__header">
      <font-awesome-icon :icon="['fal', 'sync-alt']" />
      <span>{{ $t('knowledge.toasts.article-to-guide.title') }}</span>
      <AiBadge />
    </div>
    <div class="atg-toast__body">
      <div class="atg-toast__body__steps">
        <Transition mode="out-in" name="pan" tag="div">
          <div
            v-if="status === 'PENDING'"
            :key="currentStep"
            class="atg-toast__body__steps__step"
          >
            <div class="atg-toast__body__steps__step__icon-container">
              <font-awesome-icon :icon="['fal', 'spinner-third']" spin />
              <font-awesome-icon :icon="['fal', steps[currentStep].icon]" />
            </div>
            <span>{{
              $t(
                `knowledge.toasts.article-to-guide.steps.${steps[currentStep].label}`,
              )
            }}</span>
          </div>
          <div v-if="status === 'FAILED'" class="atg-toast__body__error">
            <div class="atg-toast__body__error__icon">
              <font-awesome-icon :icon="['fal', 'times']" />
            </div>
            <div class="atg-toast__body__error__message">
              {{ $t('knowledge.toasts.article-to-guide.error') }}
            </div>
          </div>
          <div v-if="status === 'DONE'" class="atg-toast__body__success">
            <font-awesome-icon
              :icon="['fal', 'check']"
              class="atg-toast__body__success__icon"
            />
            <span class="atg-toast__body__success__message">
              {{ $t('knowledge.toasts.article-to-guide.done') }}
            </span>
          </div>
        </Transition>
      </div>
      <div v-if="status === 'PENDING'" class="atg-toast__body__actions">
        <div class="atg-toast__body__actions__stepper">
          {{ currentStep + 1 }} / {{ steps.length }}
        </div>
        <div class="atg-toast__body__actions__button">
          <button
            class="btn btn-outline btn-sm"
            @click="$emit('cancel', processId)"
          >
            {{ $t('knowledge.toasts.article-to-guide.cancel') }}
          </button>
        </div>
      </div>
    </div>
    <div class="atg-toast__footer" v-if="status === 'DONE'">
      <button class="btn btn-sm btn-primary" @click="handleGoToGuide">
        {{ $t('knowledge.toasts.article-to-guide.review') }}
      </button>
    </div>
  </div>
</template>

<script>
import AiBadge from 'components/AI/AiBadge.vue';
import { mapActions } from 'vuex';

export default {
  name: 'article-to-guide-toast',
  components: { AiBadge },
  props: {
    processId: {
      type: String,
      required: true,
    },
    articleId: {
      type: String,
      required: true,
    },
    guideId: {
      type: String,
      required: false,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    currentStep: 0,
    steps: [
      {
        icon: 'search',
        label: 'search',
      },
      {
        icon: 'person-dolly',
        label: 'draft',
      },
      {
        icon: 'digging',
        label: 'building',
      },
      {
        icon: 'pen-nib',
        label: 'introduction',
      },
      {
        icon: 'chalkboard-teacher',
        label: 'presentation',
      },
      {
        icon: 'pen-nib',
        label: 'conclusion',
      },
      {
        icon: 'search',
        label: 'review',
      },
      {
        icon: 'cog',
        label: 'draft',
      },
    ],
    stepsInterval: null,
  }),
  methods: {
    handleClose() {
      this.$emit(
        'close',
        this.processId,
        this.status === 'FAILED' || this.status === 'DONE',
      );
    },
    async handleGoToGuide() {
      const path = await this.createRedirectUrl({
        entityId: this.guideId,
        entityType: 'Diagnostic',
        target: 'knowledge',
      });

      this.$router.push(path);
      this.$emit('close', this.processId, true);
    },
    ...mapActions(['createRedirectUrl']),
  },
  created() {
    this.stepsInterval = setInterval(() => {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
      } else {
        this.currentStep = 0;
      }
    }, 5000);
  },
  watch: {
    status: {
      handler(newVal) {
        if (newVal === 'DONE' || newVal === 'FAILED') {
          clearInterval(this.stepsInterval);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.atg-toast {
  position: relative;
  width: 320px;
  background-color: white;
  border-radius: 12px;
  box-shadow: $shadow-mayday;
  transition: all 0.3s ease-in-out;

  &:hover {
    .atg-toast__close {
      display: flex;
    }
  }

  &__close {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 16px;
    height: 16px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $purple-5-mayday;
    display: none;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $purple-5-mayday;
    background-color: $grey-1-mayday;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 16px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
  }

  &__body {
    &__steps {
      display: flex;
      width: 100%;
      overflow: hidden;

      &__step {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        color: $purple-5-mayday;
        font-size: 12px;
        font-weight: 400;
        padding: 16px 16px;
        height: 60px;
        width: 100%;
        text-align: center;
        animation: pan-enter 0.8s ease-in-out;

        &__icon-container {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          svg {
            position: absolute;
          }

          svg:first-of-type {
            color: $purple-5-mayday;
            width: 24px;
            height: 24px;
          }
          svg:last-of-type {
            color: $purple-5-mayday;
            opacity: 0.4;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 8px 16px;
      color: $purple-5-mayday;

      .btn {
        color: $purple-5-mayday;
      }

      &__stepper {
        display: none;
        background-color: $purple-1-mayday;
        font-size: 10px;
        font-weight: 400;
        padding: 4px 8px;
        border-radius: 4px;
      }
    }
    &__error {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $red-5-mayday;
      font-size: 14px;
      gap: 8px;
      padding: 16px;
    }
    &__success {
      height: 60px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $purple-5-mayday;
      font-size: 14px;
      gap: 4px;
      padding: 16px;

      &__icon {
        animation: check-animation 0.8s ease-in-out;
      }

      &__message {
        animation: text-animation 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 12px 12px;
    padding: 8px 16px;
    background-color: $grey-1-mayday;
    color: $purple-5-mayday;
    overflow: hidden;

    .btn-primary {
      background-color: $purple-5-mayday;
      border-color: $purple-5-mayday;
    }
  }
}
.pan-leave-active {
  animation: pan-leave 0.8s ease-in-out;
}

@keyframes pan-enter {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes pan-leave {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes check-animation {
  0% {
    transform: translate(-30px, 10px);
  }
  70% {
    transform: translate(0, 0);
    rotate: 0;
  }
  80%,
  85% {
    rotate: 30deg;
  }
  85% {
    rotate: -10deg;
  }
  90% {
    rotate: 0deg;
    scale: 1.2;
  }
  100% {
    transform: translate(0, 0);
    rotate: 0;
    scale: 1;
  }
}

@keyframes text-animation {
  0% {
    transform: translate(10px, -10px);
  }
  70% {
    transform: translate(0, 0);
  }
  90% {
    rotate: 0deg;
    scale: 1.2;
  }
  100% {
    transform: translate(0, 0);
    rotate: 0;
    scale: 1;
  }
}
</style>
