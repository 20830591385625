export const tableFile = ({ tableFile }) => tableFile;

export const tableFileIsLoading = ({ tableFileIsLoading }) =>
  tableFileIsLoading;

export const exportSearchLoading = ({ exportSearchLoading }) =>
  exportSearchLoading;

export const exports = (state) => {
  const { exports } = state;

  return exports.map((exp) => {
    return {
      startDate: new Date(exp.startDate)
        .toLocaleDateString('fr-FR')
        .replaceAll('/', '-'),
      endDate: new Date(exp.endDate)
        .toLocaleDateString('fr-FR')
        .replaceAll('/', '-'),
      sortStartDate: exp.startDate,
      sortEndDate: exp.endDate,
      periodicity: exp.periodicity,
      exportType: exp.exportType,
      name: `${new Date(exp.startDate)
        .toLocaleDateString('fr-FR')
        .replaceAll('/', '-')}_${exp.periodicity}_${exp.exportType}`,
      address: `${exp.url}?${exp.sas}`,
    };
  });
};
